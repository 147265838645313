import React, { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePerformEventToTask from '../../../hooks/usePerformEventToTask'
import { EventsOfTask } from '../../../types'
import AcceptMultipleWtcDrawer from '../AcceptMultipleWtcDrawer'

interface IAcceptProps {
  taskUuid: string
  prevMonthTaskUuid: string
  onAction: () => void
}

const AcceptMultiple: React.FC<IAcceptProps> = ({ taskUuid, prevMonthTaskUuid, onAction }) => {
  const { t } = useFavurTranslation()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { loading: loadingPrevious, sendEvent: sendEventPrevious } = usePerformEventToTask(prevMonthTaskUuid)
  const { loading: loadingCurrent, sendEvent: sendEventCurrent } = usePerformEventToTask(taskUuid)

  const acceptCurrent = useCallback(() => sendEventCurrent(EventsOfTask.monthlySheetAccept, undefined, onAction), [
    onAction,
    sendEventCurrent,
  ])
  const acceptBoth = useCallback(() => sendEventPrevious(EventsOfTask.monthlySheetAccept, undefined, acceptCurrent), [
    acceptCurrent,
    sendEventPrevious,
  ])

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setDrawerOpen(true)
        }}
        disabled={loadingPrevious || loadingCurrent}
        data-testid="monthly-sheet-detail_accept-sheet"
      >
        {t('workTimeControl.buttons.accept')}
      </Button>
      <AcceptMultipleWtcDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        acceptBoth={acceptBoth}
        acceptCurrent={acceptCurrent}
        loading={{ previous: loadingPrevious, current: loadingCurrent }}
      />
    </>
  )
}

export default AcceptMultiple
