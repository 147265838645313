import React from 'react'
import { Box, Typography } from '@mui/material'
import { EventS } from 'icons'
import palette from 'utils/theme/palette'
import { MAX_EVENTS_COUNT } from './constants'
import { styles } from './styles'

interface IEventCountProps {
  numberOfEvents?: number
  dataTestId?: string
}

const EventCount: React.FC<IEventCountProps> = ({ numberOfEvents, dataTestId }) => {
  if (!numberOfEvents) return null

  return (
    <Box sx={styles.container} data-testid={dataTestId}>
      <EventS fill={palette.primary.main} />
      <Typography variant="caption" sx={styles.text}>
        {numberOfEvents > MAX_EVENTS_COUNT ? `${MAX_EVENTS_COUNT}+` : numberOfEvents}
      </Typography>
    </Box>
  )
}

export default EventCount
