import React, { useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import CenteredBox from 'components/CenteredBox'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AbsencePlanM, CheckMark, Documents, HomeL, TaskCenterM } from 'icons'
import { ICON_SIZE_M, ICON_SIZE_XXL } from 'icons/constants'
import { useHistory } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import theme from 'utils/theme'
import ContentLink from './ContentLink'
import { successClasses } from './styles'

const SuccessProcess: React.FC<RouteComponentProps<{ taskUuid?: string }>> = ({ match }) => {
  const {
    params: { taskUuid },
  } = match

  const { t } = useFavurTranslation()
  const history = useHistory()

  const navigateToAbsencePlan = useCallback(() => {
    history.push(`${routes.absencePlanner}`)
  }, [history])

  const navigateToDocuments = useCallback(() => {
    history.push(`${routes.documents}`)
  }, [history])

  const navigateToTasks = useCallback(() => {
    history.push(`${routes.tasks}`)
  }, [history])

  const navigateToDashboard = useCallback(() => {
    history.push(routes.dashboard)
  }, [history])

  const navigateToTaskTimeline = useCallback(() => {
    history.push(routes.secureConnectionWithTask(taskUuid as string))
  }, [taskUuid, history])

  return (
    <Page hideNativeNavigation hideWebToolbar hideHamburger hideBackground showHeader removeLastMargin>
      <CenteredBox sx={successClasses.root}>
        <Typography variant="h2" sx={successClasses.title} data-testid="pin-set-success-message">
          {t('page.secureConnection.success.title')}
        </Typography>
        <Box sx={{ marginBottom: '48px', marginTop: '48px' }}>
          <CheckMark fill={theme.palette.success.light} size={ICON_SIZE_XXL} />
        </Box>

        <Box sx={successClasses.body}>
          <Typography variant="body1">{t('page.secureConnection.success.content')}</Typography>

          <ContentLink onClick={navigateToDocuments} dataTestId="documents-link">
            <Documents fill={theme.palette.primary.main} size={ICON_SIZE_M} />
            <Typography variant="body1">{t('page.secureConnection.success.content.documents')}</Typography>
          </ContentLink>

          <ContentLink onClick={navigateToAbsencePlan} dataTestId="absence-plan-link">
            <AbsencePlanM fill={theme.palette.primary.main} size={ICON_SIZE_M} />
            <Typography variant="body1">{t('page.secureConnection.success.content.absencePlan')}</Typography>
          </ContentLink>

          <ContentLink onClick={navigateToTasks} dataTestId="tasks-link">
            <TaskCenterM fill={theme.palette.primary.main} size={ICON_SIZE_M} />
            <Typography variant="body1">{t('page.secureConnection.success.content.tasks')}</Typography>
          </ContentLink>

          <ContentLink onClick={navigateToDashboard} dataTestId="dashboard-link">
            <HomeL fill={theme.palette.primary.main} size={ICON_SIZE_M} />
            <Typography variant="body1">{t('page.secureConnection.success.content.dashboard')}</Typography>
          </ContentLink>
        </Box>

        {taskUuid && (
          <ActionCardButtons>
            <Button variant="contained" color="secondary" onClick={navigateToTaskTimeline} data-testid="back-timeline">
              {t('page.secureConnection.success.button')}
            </Button>
          </ActionCardButtons>
        )}
      </CenteredBox>
    </Page>
  )
}

export default SuccessProcess
