import palette from '../../../../../utils/theme/palette'

export const classes = {
  button: {
    flex: 1,
    maxWidth: '200px',
  },
  resetButton: {
    backgroundColor: palette.primary[50],
    color: palette.primary[700],
    '&:hover': {
      backgroundColor: palette.primary[50],
    },
  },
}
