import React, { useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import DialogWindow from '../../../components/Dialog/DialogWindow'
import useFavurTranslation from '../../../hooks/useFavurTranslation'
import usePersonsOfUser from '../../../hooks/usePersonsOfUser'
import useTasksQueryPaginated from '../../../hooks/useTasksQueryPaginated'
import routes from '../../../services/RoutesProvider/routes'
import { taskTypes } from '../../../shared/constants'
import { sortArrayByKey } from '../../../utils/sort'
import { taskStates } from '../../Tasks/constants'
import { TaskDataT, TaskT } from '../../Tasks/types'
import { classes } from './styles'

const SecureConnectionDialog: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  const { persons: userPersons } = usePersonsOfUser()
  const { list: secureConnectionTasks } = useTasksQueryPaginated({
    initialFilters: {
      /* eslint-disable camelcase */
      task_types: [taskTypes.secureConnection],
      task_statuses: [taskStates.todo],
      person_ids: userPersons ? userPersons.map((person) => person.id as number) : [],
      /* eslint-enable camelcase */
    },
    reducedFields: true,
  })

  const sortedTasks: TaskT<TaskDataT>[] = sortArrayByKey(secureConnectionTasks, 'insertedAt')
  const favurUuid = sortedTasks.length ? sortedTasks[0].favurUuid : undefined

  const goToTutorial = useCallback(() => {
    favurUuid && history.replace(routes.secureConnectionWithTask(favurUuid))
  }, [favurUuid, history])

  const goToStepper = () => history.replace(routes.securitySetup)

  return (
    <>
      <Box sx={classes.header}>
        <Typography sx={classes.text} variant="subtitle1">
          {t('secureConnection.dialog.hiddenContent')}
        </Typography>
      </Box>
      <DialogWindow
        titleText={t('secureConnection.dialog.title')}
        bodyText={t('secureConnection.dialog.body')}
        onConfirm={favurUuid ? goToTutorial : undefined}
        confirmText={t('secureConnection.dialog.confirm')}
        onCancel={goToStepper}
        cancelText={t('secureConnection.dialog.cancel')}
        open
      />
    </>
  )
}

export default SecureConnectionDialog
