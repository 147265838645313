import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const tokenClasses = {
  errorAlert: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
    gap: '4px',
  },
  inputContainer: {
    height: theme.spacing(2.5625),
  },
  label: {
    color: theme.palette.text.secondary,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '16px',
    justifyContent: 'space-between',
    marginBottom: `calc(100px + var(--ion-safe-area-bottom))`,
  },
  token: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}

export const tenantClasses = {
  labelSelector: {
    fontSize: 12,
  },
  name: {
    color: theme.palette.text.primary,
    padding: 0,
    paddingBottom: '3px',
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    width: '260px',
    textAlign: 'left',
    paddingLeft: '12px',
  },
  root: {
    marginTop: '24px',
    marginBottom: '42px',
    width: '272px',
  },
  selector: {
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 0,
    textAlign: 'left',
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      padding: '16px 32px 8px 12px',
    },
    '& .MuiSelect-icon': {
      paddingTop: '8px',
    },
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
}

export const inputClasses = {
  active: {
    borderBottom: `3px solid ${theme.palette.primary.light}`,
  },
  input: {
    letterSpacing: '-0.4px',
    color: theme.palette.text.primary,
    fontFamily: 'Signika, sans-serif',
    fontSize: theme.spacing(2.0625),
    textAlign: 'center',
    height: theme.spacing(2.5625),
    padding: 0,
    paddingBottom: '8px',
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    width: '272px',
  },
  error: {
    borderBottom: `3px solid ${palette.error.main}`,
  },
  errorLabel: { color: palette.error.main },
  labelSuccess: { color: palette.success.main },
  success: {
    borderBottom: `3px solid ${palette.success.main}`,
  },
}
