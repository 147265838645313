import React from 'react'
import PinLogin from 'pages/PinLogin'
import { RouteComponentProps } from 'react-router-dom'
import TeamPageContent from './TeamPageContent'

const TeamPage: React.FC<RouteComponentProps<{ tab?: string; uuid: string }>> = ({ match }) => {
  const { tab, uuid: teamUuid } = match.params

  return (
    <PinLogin>
      <TeamPageContent tab={tab} uuid={teamUuid} />
    </PinLogin>
  )
}

export default TeamPage
