import React from 'react'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import Page from 'components/Page'
import Timeline from 'components/Timeline'
import { Locale } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import { refreshHighSecTriggers } from 'pages/Tasks/constants'
import { headerClasses } from 'pages/Tasks/styles'
import { MonthlySheetTaskT, TaskT } from 'pages/Tasks/types'
import { extraBottomSpacingDetailPage } from '../constants'
import ActionButtons from './ActionButtons'
import PastMonthReminder from './PastMonthReminder'
import usePastMonthWtc from './usePastMonthWtc'
import { getTimelineForComponent } from './utils'

interface ITaskDetailsProps {
  task: TaskT<MonthlySheetTaskT>
  isOffice: boolean
  title: string
  refetch: () => void
  onClickBackIcon?: () => void
  showHeader?: boolean
}

const Content: React.FC<ITaskDetailsProps> = ({
  task,
  isOffice,
  onClickBackIcon,
  showHeader = false,
  title,
  refetch,
}) => {
  const { t, locale } = useFavurTranslation()
  const { personIds } = useRolesViews()
  const { prevMonthTask } = usePastMonthWtc({ currentMonthTask: task })

  const timelineItems = getTimelineForComponent({ task, t, locale: locale as Locale, personIds, isOffice })
  return (
    <Page
      header={<BackwardLink onClickIcon={onClickBackIcon} title={title} />}
      hideNativeNavigation
      hasFloatingButtons
      hideHamburger
      hideBackground
      removeLastMargin
      showHeader={showHeader}
      sxHeader={headerClasses.subHeader}
      extraBottomMargin={extraBottomSpacingDetailPage}
    >
      <Box {...refreshHighSecTriggers}>
        {prevMonthTask && <PastMonthReminder pastTaskUuid={prevMonthTask.favurUuid} currentTaskUuid={task.favurUuid} />}
        <Timeline items={timelineItems} />
        <ActionButtons task={task} isOffice={isOffice} prevMonthTask={prevMonthTask} onAction={refetch} />
      </Box>
    </Page>
  )
}

export default Content
