import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import Numpad from 'components/Numpad'
import PinDisplay from 'components/Numpad/PinDisplay'
import { PIN_LENGTHS } from 'constants/highSecurityConnection'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes as secureConnection } from '../styles'
import Continue from './ActionButtons/Continue'
import { classes } from './styles'

interface IPinEnter {
  onContinue: () => void
  pin: string
  pinLength: number
  setPin: (pin: string | ((prevPin: string) => string)) => void
  setPinLength: (pinLength: number | ((prevPinLength: number) => number)) => void
}

const PinEnter: React.FC<IPinEnter> = ({ onContinue, pin, pinLength, setPin, setPinLength }) => {
  const { t } = useFavurTranslation()

  const getPinChangeLength = () => {
    return pinLength === PIN_LENGTHS.long ? PIN_LENGTHS.short : PIN_LENGTHS.long
  }
  const changePinLength = () => {
    setPinLength(getPinChangeLength())
  }

  return (
    <>
      <Typography variant="h2" sx={secureConnection.title}>
        {t('secureConnection.setPin.pinEnter.title')}
      </Typography>

      <Box sx={classes.pinDisplayContainer}>
        <PinDisplay pinLength={pinLength} pin={pin} secret />
        <Button sx={classes.changePinLengthButton} onClick={changePinLength} data-testid="change-pin-length">
          {t('securitySetup.pinEnter.changePinLength', { pinLength: getPinChangeLength() })}
        </Button>
      </Box>
      <Box sx={classes.numpadContainer}>
        <Numpad numpadType="pin" onNumpadValueChange={setPin} outputLength={pinLength} />
      </Box>

      <ActionCardButtons>
        <Continue onClick={onContinue} disabled={pin.length !== pinLength} data-testid="set-pin-continue" />
      </ActionCardButtons>
    </>
  )
}

export default PinEnter
