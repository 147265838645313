import { useState } from 'react'
import { findAllPersonsWithOwnerAndPermissionsQuery } from 'shared/queries'
import type { OwnerT, PersonT } from 'types'
import { getOwnerPersonsFromPersonList } from 'utils/person'
import { useJamesApolloQuery } from './useJamesApolloQuery'

export type OwnerPersons = { owner: OwnerT; personIds: number[]; persons: PersonT[] }
export type OwnersPropsT = {
  teamManagersOnly?: boolean
}

export const useOwnersOfUser = ({ teamManagersOnly = false }: OwnersPropsT) => {
  const [owners, setOwners] = useState<OwnerPersons[]>([])

  const { loading } = useJamesApolloQuery<{ findAllPersons: PersonT[] }>(findAllPersonsWithOwnerAndPermissionsQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const persons = response?.findAllPersons
      const ownersList = getOwnerPersonsFromPersonList(persons, teamManagersOnly)
      setOwners(ownersList)
    },
  })

  return { loading, owners }
}
