import theme from 'utils/theme'

export const classes = {
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: `calc(100px + var(--ion-safe-area-bottom))`,
    padding: '16px',
  },
  title: {
    color: theme.palette.primary.main,
    lineHeight: 1.26,
    fontWeight: 600,
  },
}

export const headerClasses = {
  subHeader: {
    backgroundColor: 'white !important',
    color: `${theme.palette.primary.main}  !important`,
    zIndex: 10,
    '&[stuck="true"]': {
      boxShadow: theme.shadows[5],
    },
  },
}
