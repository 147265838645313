import theme from 'utils/theme'
import palette from 'utils/theme/palette'

const commonTextStyles = {
  fontWeight: 'regular',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: -0.3,
  margin: 0,
  overflowWrap: 'break-word',
}
export const styles = {
  divider: {
    backgroundColor: palette.primary[50],
    height: '2px',
    width: '100%',
    borderRadius: '1px',
  },
  textBlock: {
    ...commonTextStyles,
    '& h2': {
      ...commonTextStyles,
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '24px', // You can add or override any specific styles here
      margin: '16px 0',
    },
    '& h3': {
      ...commonTextStyles,
      fontWeight: 'bold',
      fontSize: '18px',
      margin: '16px 0',
    },
    '& h4': {
      ...commonTextStyles,
      fontWeight: 'bold',
      margin: '16px 0',
    },
    '& p': commonTextStyles,
    '& b': {
      ...commonTextStyles,
      fontWeight: 'bold',
    },
    '& a': commonTextStyles,
  },
}

export const imageBlockStyles = {
  image: {
    borderRadius: '8px',
  },
}

export const linkBlockStyles = {
  button: {
    color: theme.palette.primary.main,
    width: '100%',
    padding: '9px 24px',
    margin: '8px 0',
    '&:hover:before': {
      background: 'transparent',
    },
  },
  containedButton: {
    //@ts-ignore
    background: theme.palette.primary[50],
    '&:hover': {
      //@ts-ignore
      background: theme.palette.primary[50],
    },
  },
}

export const subcontentBlockStyles = {
  card: {
    display: 'flex',
    gap: '16px',
    border: `1px solid #DADEF2`,
    borderRadius: '8px',
    width: '100%',
    maxHeight: '107px',
    height: 'fit-content',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  image: {
    width: '107px',
    height: '107px',
  },
  imageContainer: {
    display: 'flex',
  },
  container: {
    padding: '16px 0',
    display: 'flex',
    gap: '16px',
    width: '100%',
    justifyContent: 'space-between',
  },

  textContainer: {
    width: '100%',
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'FiraSans, sans-serif',
  },

  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
  oneLine: {
    WebkitLineClamp: '1',
  },
  iconContainer: {
    paddingRight: '16px',
  },
}
