import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'

const Start: React.FC<{ taskUuid: string }> = ({ taskUuid }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        history.push(`${routes.securitySetup}?taskUuid=${taskUuid}`)
      }}
    >
      {t('secureConnection.buttons.start')}
    </Button>
  )
}

export default Start
