import theme from 'utils/theme'

export const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  header: {
    display: 'flex',
    height: '24px',
    flexDirection: 'column',
    marginTop: '24px',
  },
  buttonClose: {
    color: theme.palette.primary.main,
    alignSelf: 'flex-end',
  },
  image: {
    width: '392px',
    height: '449px',
    marginBottom: theme.spacing(1.5),
    alignSelf: 'center',
    objectFit: 'contain',
  },
  icon: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignSelf: 'center',
  },
  textTitle: (hasIcon: boolean) => ({
    color: theme.palette.grey[hasIcon ? 900 : 600],
    marginBottom: theme.spacing(hasIcon ? 1 : 0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  }),
  text: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    textAlign: 'center',
    width: '100%',
  },
  buttonGoTo: {
    minWidth: theme.spacing(7.5),
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  body: {
    flexGrow: 1,
  },
  footer: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
  },
}
