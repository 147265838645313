import React, { useCallback, useState } from 'react'
import { Box, Button, SxProps, Theme, Typography } from '@mui/material'
import { DeletionConfirmed } from 'components/TaskBadge'
import TextInputDrawer from 'components/TextInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AbsenceRequestTaskT, EventsOfTask, TaskT } from 'pages/Tasks/types'
import usePerformEventToTask from '../../../hooks/usePerformEventToTask'
import { classes } from './styles'

interface IConfirmDeletionProps {
  task: TaskT<AbsenceRequestTaskT>
  onAction: () => void
  drawerSx?: SxProps<Theme>
}

const MAX_LENGTH_COMMENT = 2000

const ConfirmDeletion: React.FC<IConfirmDeletionProps> = ({ task, onAction, drawerSx }) => {
  const { t } = useFavurTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const onCompleted = useCallback(() => {
    setOpenDrawer(false)
    onAction()
  }, [onAction])
  const { sendEvent, loading } = usePerformEventToTask(task.favurUuid)

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setOpenDrawer(true)
        }}
        data-testid="absence-request_action-confirm_deletion"
      >
        {t('absenceRequest.buttons.confirmDeletion')}
      </Button>

      <TextInputDrawer
        isOpen={openDrawer}
        onCancel={() => {
          setOpenDrawer(false)
        }}
        onSuccess={(inputValue: string) =>
          sendEvent(EventsOfTask.absenceRequestAcceptDeletion, inputValue, onCompleted)
        }
        successButtonText={t('absenceRequest.drawer.button.comment')}
        textFieldMaxLength={MAX_LENGTH_COMMENT}
        textFieldPlaceHolder={t('absenceRequest.requestDeletion.comment.placeholder')}
        successButtonDisabled={loading}
        testIdName="confirm_deletion"
        paperSx={drawerSx}
      >
        <Box sx={classes.commentHeaderContainer}>
          <Box sx={classes.commentBadgeContainer}>
            <DeletionConfirmed isActing />
          </Box>
          <Typography variant="body2" sx={classes.commentTextBody}>
            {t('absenceRequest.confirmDeletion.body')}
          </Typography>
        </Box>
      </TextInputDrawer>
    </>
  )
}

export default ConfirmDeletion
