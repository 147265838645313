import { alpha } from '@mui/material/styles'
import { ICON_SIZE_L } from 'icons/constants'
import theme from 'utils/theme'
import palette from 'utils/theme/palette'
import shadows from 'utils/theme/shadows'

export const actionCardButtonsClasses = {
  actionButtonsContainer: {
    display: 'flex',
  },
  cancelButton: {
    flexGrow: 1,
    marginRight: theme.spacing(0.5),
  },
  saveButton: {
    flexGrow: 1,
    marginRight: theme.spacing(0.5),
  },
  paper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
    borderRadius: '6px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingBottom: 'var(--ion-safe-area-bottom)',
    zIndex: 10,
  },
  smallPaper: (hasOverflow: boolean, isAtBottom: boolean) => ({
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    '&::before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 24,
      right: 24,
      content: '""',
      zIndex: -1,
      boxShadow: hasOverflow && !isAtBottom ? shadows[9] : 'none',
    },
    boxShadow: 'none',
  }),
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    minHeight: '84px',
    alignItems: 'center',
  },
  rowButtons: {
    display: 'flex',
    height: '36px',
    gap: '16px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomDivisionLineContainer: {
    margin: '0 24px',
  },
  floatingContainer: {
    position: 'fixed',
    bottom: 0,
    maxWidth: '576px', // For bigger screens, 600px - 16px and 16px of lateral padding
    width: 'calc(100% - 24px)', // We need this for smaller screens
    display: 'flex',
  },
  floatingValidateInactive: {
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      color: 'white',
      boxShadow: theme.shadows[6],
    },
  },
  floatingValidateActive: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  floatingButtonCancel: {
    backgroundColor: 'white',
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'white',
      '&:before': {
        backgroundColor: alpha(palette.secondary[400], 0.04),
      },
    },
  },
  floatingButtonBase: {
    '&:before': {
      borderRadius: 100,
      content: '" "',
      position: 'absolute',
      zIndex: 1,
      height: '100%',
      width: '100%',
      left: 0,
      top: 0,
    },
    flex: 1,
    marginBottom: theme.spacing(1.5),
    height: theme.spacing(2.3),
  },
  floatingButtonSave: {
    marginLeft: '16px',
    '&:hover': {
      backgroundColor: palette.secondary.main,
      '&:before': {
        backgroundColor: alpha(palette.secondary[900], 0.12),
      },
    },
  },
  floatingButtonNativeMargin: {
    bottom: `calc(max(var(--ion-safe-area-bottom), ${theme.spacing(0.75)}) + ${ICON_SIZE_L}px + ${theme.spacing(1.5)})`,
  },
  floatingButtonNativeNoNavigation: {
    bottom: `max(var(--ion-safe-area-bottom), ${theme.spacing(0.75)})`,
    marginBottom: 0,
  },
}
