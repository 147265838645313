import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils'
import TimeBalance from './TimeBalanceContainer'
import { IBalacesSwipeableProps } from './types'

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const BalancesSwipeable: React.FC<IBalacesSwipeableProps> = ({ resetDates, handleSwipeAction, swipeIndex }) => {
  return (
    <VirtualizeSwipeableViews
      index={swipeIndex}
      overscanSlideAfter={swipeIndex < 0 ? 1 : 0}
      overscanSlideBefore={1}
      onChangeIndex={(newIndex, indexLatest) => {
        if (newIndex > 0) {
          return
        }

        handleSwipeAction(newIndex < indexLatest ? -1 : 1)
      }}
      slideRenderer={(params) => (
        <TimeBalance resetDates={resetDates} swipeIndex={swipeIndex} key={params.key} index={params.index} />
      )}
      enableMouseEvents
    />
  )
}

export default BalancesSwipeable
