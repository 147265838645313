import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, MenuItem, Typography, Select, SelectChangeEvent, InputLabel, FormControl } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import CenteredBox from 'components/CenteredBox'
import Numpad from 'components/Numpad'
import { TOKEN_LENGTH, TOKEN_STATUSES } from 'constants/highSecurityConnection'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePinContext from 'hooks/usePinContext'
import { AlertInfoS, AlertSuccessS } from 'icons'
import { ArrowDownS } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { ExtendedStepProps, VerifyStepProps } from 'types'
import theme from 'utils/theme'
import palette from 'utils/theme/palette'
import { classes as securityClasses } from '../../styles'
import { PersonData } from '../../types'
import { classes as secureConnectionClasses } from '../styles'
import Verify from './ActionButtons/Verify'
import { inputClasses, tenantClasses, tokenClasses as classes } from './styles'

interface IVerifyTokenProps {
  goToStep: (stepName: string, additionalUrlParams?: string[]) => void
  additionalProperties?: ExtendedStepProps
}

type OptionTenant = {
  personId: string
  tenantName: string
}

const VerifyToken: React.FC<IVerifyTokenProps> = ({ goToStep, additionalProperties }) => {
  const props = additionalProperties as VerifyStepProps
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { setPinContext } = usePinContext()
  const [token, setToken] = useState('')
  const [nextPage, setNextPage] = useState<string | undefined>(undefined)
  const [personData, setPersonData] = useState<OptionTenant>({
    personId: '',
    tenantName: '',
  })
  const [showSuccess, setShowSuccess] = useState<boolean | undefined>(undefined)

  const { lowSecTenants, taskUuid, nextAction, onVerifyToken: onVerify } = props

  useEffect(() => {
    if (lowSecTenants?.length === 1) {
      setPersonData({
        personId: String(lowSecTenants[0].personId),
        tenantName: lowSecTenants[0].tenantName,
      })
    }
  }, [lowSecTenants, taskUuid])

  const isSingleTenant = useMemo(() => {
    return lowSecTenants?.length === 1
  }, [lowSecTenants])

  useEffect(() => {
    // Redirection to the next step after 2 seconds
    if (!showSuccess) return undefined

    const successTimeout = setTimeout(() => {
      if (nextPage === 'pin') {
        const query = taskUuid ? `?taskUuid=${taskUuid}` : ''
        goToStep('SET_PIN', [query])
      }
      if (nextPage === 'success') {
        const path = taskUuid ? `${routes.secureConnectionSuccess}/${taskUuid}` : routes.secureConnectionSuccess
        history.push(path)
      }
    }, 2000)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [showSuccess, nextPage, history, goToStep, taskUuid])

  const borderBottomStyle = useMemo(() => {
    if (showSuccess === true) {
      return inputClasses.success
    }
    if (showSuccess === false) {
      return inputClasses.error
    }

    if (token.length > 0) {
      return inputClasses.active
    }
    return {}
  }, [showSuccess, token])

  const handleOnVerify = useCallback(async () => {
    if (onVerify) {
      const response = await onVerify(Number(personData.personId), token)
      if (response.establishSecureConnection.status === TOKEN_STATUSES.connectionEstablished) {
        setNextPage(nextAction)
        setShowSuccess(true)
      }
      if (response.establishSecureConnection.status === TOKEN_STATUSES.successGoToPin) {
        setNextPage(nextAction)
        setShowSuccess(true)
        setPinContext({ securitySetupTokenConfirmed: true })
      }
      if (response.establishSecureConnection.status === TOKEN_STATUSES.error) {
        setShowSuccess(false)
      }
    }
  }, [onVerify, personData.personId, token, nextAction, setPinContext])

  const handleTenantChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const pair = lowSecTenants?.find((e: PersonData) => `${e.personId}` === `${event.target.value}`)
      if (!pair) {
        setPersonData({ personId: '', tenantName: '' })
        return
      }
      setPersonData({ personId: String(pair.personId), tenantName: pair.tenantName })
    },
    [lowSecTenants, setPersonData],
  )

  const handleNumpadChange = useCallback((newValue: string) => {
    setToken(newValue)
    setShowSuccess(undefined)
  }, [])

  if (lowSecTenants === undefined) {
    return null
  }

  return (
    <CenteredBox sx={secureConnectionClasses.root}>
      <Typography variant="h2" sx={secureConnectionClasses.title}>
        {t('secureConnection.verifyToken.content.title')}
      </Typography>

      {isSingleTenant ? (
        <Box sx={tenantClasses.root} data-testid="verify-token-tenant-name">
          <Typography variant="caption" sx={classes.label}>
            {t('secureConnection.verifyToken.tenant.label')}
          </Typography>
          <Typography variant="body1" sx={tenantClasses.name}>
            {personData.tenantName}
          </Typography>
        </Box>
      ) : (
        <FormControl>
          <InputLabel id="test-select-label" shrink={false} sx={tenantClasses.labelSelector}>
            {t('secureConnection.verifyToken.tenantSelect.label')}
          </InputLabel>

          <Select
            fullWidth
            inputProps={{ 'data-testid': 'verify-token-select-tenant' }}
            sx={[tenantClasses.root, tenantClasses.selector]}
            value={personData.personId}
            onChange={handleTenantChange}
            disabled={isSingleTenant}
            IconComponent={() => <ArrowDownS fill={theme.palette.secondary.main} />}
          >
            {lowSecTenants?.map((tenantPerson: PersonData) => (
              <MenuItem
                key={String(tenantPerson.personId)}
                value={String(tenantPerson.personId)}
                data-testid={`verify-token-tenant-option-${tenantPerson.personId}`}
              >
                {tenantPerson.tenantName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Box sx={classes.token}>
        <Typography
          variant="caption"
          sx={{
            ...classes.label,
            ...(showSuccess === true && inputClasses.labelSuccess),
          }}
        >
          {t('secureConnection.verifyToken.content.inputLabel')}
        </Typography>
        <Box sx={classes.inputContainer} data-testid="token">
          <Typography variant="h3" sx={[inputClasses.input, ...[borderBottomStyle]]} data-testid="verify-token-input">
            {token} {showSuccess && <AlertSuccessS fill={palette.alert.success.icon} />}
          </Typography>
          {showSuccess === false && (
            <Box sx={classes.errorAlert}>
              <AlertInfoS fill={palette.alert.error.icon} />
              <Typography variant="caption" sx={inputClasses.errorLabel} data-testid="flashtext-error-message">
                {t('secureConnection.verifyToken.content.errorLabel')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={securityClasses.numpad}>
        <Numpad outputLength={6} onNumpadValueChange={handleNumpadChange} numpadType="pin" />
      </Box>
      <ActionCardButtons>
        <Verify onClick={handleOnVerify} disabled={token.length !== TOKEN_LENGTH || personData.personId === ''} />
      </ActionCardButtons>
    </CenteredBox>
  )
}

export default VerifyToken
