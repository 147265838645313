import React, { useCallback, useContext, useEffect } from 'react'
import { Box, Card, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useFileDisplay } from 'hooks/useFileDownload'
import { ArrowRightS, DocumentPDFL } from 'icons'
import { styles } from 'pages/Cms/styles'
import FlashMessagesContext from 'services/FlashMessages/context'
import { DocumentContentBlockT } from 'types'
import palette from 'utils/theme/palette'
import { getFilenameWithoutTimestamp } from '../utils'

interface IDocumentBlockProps {
  data: DocumentContentBlockT
}

const DocumentBlock: React.FC<IDocumentBlockProps> = ({ data }) => {
  const { t } = useFavurTranslation()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const { displayFile, error: displayError } = useFileDisplay()

  useEffect(() => {
    removeAll()
    if (displayError) {
      setFlashMessage(t('page.documents.download.error'))
    }
  }, [displayError, removeAll, setFlashMessage, t])

  const viewFile = useCallback(() => {
    const filename = data.src.substring(data.src.lastIndexOf('/') + 1)
    displayFile({ url: data.src, filename: filename })
  }, [data.src, displayFile])

  return (
    <Box component="a" onClick={viewFile} sx={styles.contentCardLink}>
      <Card variant="outlined" sx={styles.contentCard}>
        <Box sx={styles.iconAndText}>
          <Box sx={styles.icon}>
            <DocumentPDFL />
          </Box>
          <Box sx={styles.contentCardText}>
            <Typography variant="subtitle1">{getFilenameWithoutTimestamp({ filename: data.filename })}</Typography>
            <Typography variant="caption">{data.filesize}</Typography>
          </Box>
        </Box>
        <ArrowRightS fill={palette.primary.main} />
      </Card>
    </Box>
  )
}

export default DocumentBlock
