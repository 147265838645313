import React, { useCallback } from 'react'
import TextInputDrawer from 'components/TextInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventsOfTask } from 'pages/Tasks/types'
import usePerformEventToTask from '../../../hooks/usePerformEventToTask'
import DefaultHeader from './DefaultHeader'

interface ISendCommentProps {
  isOpen: boolean
  onCancel: () => void
  taskUuid: string
  eventOnSuccess: EventsOfTask
  eventCompleted: () => void
  children?: React.ReactNode
  required?: boolean
}

const MAX_LENGTH_COMMENT = 2000

const SendComment: React.FC<ISendCommentProps> = ({
  isOpen,
  onCancel,
  taskUuid,
  eventOnSuccess,
  eventCompleted,
  children,
  required = false,
}) => {
  const { t } = useFavurTranslation()
  const { loading, sendEvent } = usePerformEventToTask(taskUuid)

  const onRequestChange = useCallback((comment: string) => sendEvent(eventOnSuccess, comment, eventCompleted), [
    eventCompleted,
    eventOnSuccess,
    sendEvent,
  ])

  return (
    <TextInputDrawer
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onRequestChange}
      successButtonText={t('workTimeControl.requestChange.successButton')}
      textFieldMaxLength={MAX_LENGTH_COMMENT}
      textFieldPlaceHolder={t('workTimeControl.requestChange.inputPlaceholder')}
      successButtonDisabled={loading}
      required={required}
      testIdName="monthly-sheet-detail_comment"
    >
      {children ? children : <DefaultHeader />}
    </TextInputDrawer>
  )
}

export default SendComment
