import { useContext, useEffect } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import BannerMessagesContext from 'services/BannerMessages/context'
import { IBannerMessage } from 'services/BannerMessages/types'
import { lowSecurityTenantQuery } from 'shared/queries'
import { TenantNamesT } from 'types'

export const lowSecurityBannerDetails: IBannerMessage = {
  message: 'page.banner.notHighSec.content',
  bannerActions: [
    {
      action: 'SECURITY_SETUP',
      text: 'page.banner.notHighSec.button',
    },
  ],
}

const useBannerHighSecurityConnectionEnable = () => {
  const { setBannerMessage } = useContext(BannerMessagesContext)

  const { data: lowSecurityTenantNamesData, loading } = useJamesApolloQuery<{ lowSecurityTenantNames: TenantNamesT[] }>(
    lowSecurityTenantQuery,
    { fetchPolicy: 'no-cache' },
  )

  useEffect(() => {
    if (loading) return undefined
    if (lowSecurityTenantNamesData && lowSecurityTenantNamesData?.lowSecurityTenantNames?.length < 1) return undefined
    setBannerMessage(lowSecurityBannerDetails)
    return () => {
      setBannerMessage(undefined)
    }
  }, [setBannerMessage, lowSecurityTenantNamesData, loading])

  return {
    loading: loading,
  }
}

export default useBannerHighSecurityConnectionEnable
