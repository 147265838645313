import { useContext } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { useHistory } from 'react-router'
import BannerMessagesContext from 'services/BannerMessages/context'
import { invitationQuery } from 'shared/queries'
import { InvitationT } from 'types'
import { ApolloError } from '@apollo/client'

const useInvitation = (inviteCode?: string) => {
  const history = useHistory()
  const { setBannerMessage } = useContext(BannerMessagesContext)

  const { data, loading } = useJamesApolloQuery<{ invitation: InvitationT }>(invitationQuery, {
    variables: {
      code: inviteCode,
    },
    skip: !inviteCode,
    onError: (error: ApolloError) => {
      //@ts-ignore
      const code = error.graphQLErrors[0].code
      if (code === 'invitation.error.be.noInvitationWithCode') {
        setBannerMessage({
          message: code,
          bannerActions: [
            { text: 'common.dialog.button.contact', action: 'CONTACT' },
            { text: 'common.dialog.button.close', action: 'CLOSE' },
          ],
        })
      }
      history.replace('/')
    },
  })

  return { invitation: data?.invitation, loading }
}

export default useInvitation
