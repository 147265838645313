import React from 'react'
import { Box, Typography } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import { NumpadWithoutState } from 'components/Numpad'
import PinDisplay from 'components/Numpad/PinDisplay'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AlertInfoS } from 'icons'
import palette from 'utils/theme/palette'
import { classes as secureConnection } from '../styles'
import Confirm from './ActionButtons/Confirm'
import Reset from './ActionButtons/Reset'
import { classes } from './styles'

export interface IPinRepeat {
  onConfirm: () => void
  onReset: () => void
  pin: string
  pinLength: number
  setPin: (pin: string) => void
  showErrorMatching: boolean
}

const PinRepeat: React.FC<IPinRepeat> = ({ onConfirm, onReset, pin, pinLength, setPin, showErrorMatching }) => {
  const { t } = useFavurTranslation()

  return (
    <>
      <Typography variant="h2" sx={secureConnection.title}>
        {t('secureConnection.setPin.pinRepeat.title')}
      </Typography>

      <Box sx={classes.pinDisplayContainer}>
        <PinDisplay pinLength={pinLength} pin={pin} secret />
      </Box>
      {showErrorMatching === true && (
        <Box sx={classes.errorAlert} data-testid="set-pin-error-alert">
          <AlertInfoS fill={palette.alert.error.icon} />
          <Typography variant="caption" sx={classes.errorLabel} data-testid="flashtext-error-message">
            {t('secureConnection.setPin.pinRepeat.errorMatchingLabel')}
          </Typography>
        </Box>
      )}
      <Box sx={classes.numpadContainerRepeat}>
        <NumpadWithoutState numpadType="pin" numpadValue={pin} setNumpadValue={setPin} outputLength={pinLength} />
      </Box>

      <ActionCardButtons>
        <Box sx={classes.pinRepeatButtonsContainer}>
          <Reset onClick={onReset} />
          <Confirm onClick={onConfirm} disabled={pin.length !== pinLength} data-testid="set-pin-confirm" />
        </Box>
      </ActionCardButtons>
    </>
  )
}

export default PinRepeat
