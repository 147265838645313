import React, { useState, useEffect } from 'react'
import { AuthenticationKind } from 'constants/authentication'
import useSessionContext from 'hooks/useSessionContext'
import LoadingPage from 'pages/Loading'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import Failure from './Failure'
import Invite from './Invite'
import InviteExistingUser from './InviteExistingUser'
import Register from './Register'
import SmsValidation from './SmsValidation'
import Success from './Success'
import { Step, IInvitationStepProps } from './types'
import useInvitation from './useInvitation'
import { getInvitationRedirect, InvitationRedirect } from './utils'

const getStepComponent = (step: Step): React.FC<IInvitationStepProps> => {
  switch (step) {
    case 'INVITE_EXISTING_USER':
      return InviteExistingUser
    case 'INVITE':
      return Invite
    case 'SMS_VALIDATION':
      return SmsValidation
    case 'REGISTER':
      return Register
    case 'SUCCESS':
      return Success
    case 'FAILURE':
      return Failure
    default:
      return Invite
  }
}

const InvitationPage: React.FC<RouteComponentProps<{ inviteCode?: string }>> = ({ match }) => {
  const { auth, phone } = useSessionContext()
  const { inviteCode } = match.params

  // Allow only this characters https://hexdocs.pm/elixir/Base.html#module-base-64-url-and-filename-safe-alphabet
  const sanitizedCode = (inviteCode || '').replace(/[^a-z0-9_\-=]/gi, '')

  const { invitation, loading } = useInvitation(sanitizedCode)
  const history = useHistory()

  const [step, setStep] = useState<Step>(() => {
    if (auth === AuthenticationKind.ANONYMOUS) {
      return 'INVITE'
    }
    return 'INVITE_EXISTING_USER'
  })

  const StepComponent = getStepComponent(step)

  useEffect(() => {
    if (!loading) {
      switch (getInvitationRedirect(auth, invitation, phone)) {
        case InvitationRedirect.INVITATION_VALID:
          break
        case InvitationRedirect.REDIRECT_SUCCESS:
          setStep('SUCCESS')
          break
        case InvitationRedirect.REDIRECT_HOME:
          history.push('/')
          break
        default:
          history.push('/')
      }
    }
  }, [invitation, history, auth, phone, loading])

  if ((inviteCode && loading) || !invitation) {
    return <LoadingPage />
  }

  return <StepComponent setStep={setStep} invitation={invitation} />
}

export default InvitationPage
