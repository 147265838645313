import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import redesignTheme from 'redesign/themes/main'

interface IPOCPageProps {
  children?: React.ReactNode
}

const POCPage: React.FC<IPOCPageProps> = ({ children }) => {
  return <ThemeProvider theme={redesignTheme}>{children}</ThemeProvider>
}

export default POCPage
