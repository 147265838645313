import { useContext } from 'react'
import { EventsOfTask } from 'pages/Tasks/types'
import { MutationResultWithNotManaged } from 'types'
import { useMutation } from '@apollo/client'
import useFavurTranslation from '../../../hooks/useFavurTranslation'
import FlashMessagesContext from '../../../services/FlashMessages/context'
import { performEventToTask } from '../queries'

const usePerformEventToTask = (favurUuid: string) => {
  const { removeAll, setFlashMessage } = useContext(FlashMessagesContext)
  const { t } = useFavurTranslation()

  const [performEventToTaskFunction, { loading }] = useMutation<{ performEventToTask: MutationResultWithNotManaged }>(
    performEventToTask,
  )

  const sendReminderToFrontline = () => {
    return performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.sendReminderToFrontline,
      },
    })
  }

  const sendReminderToManager = () => {
    return performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.sendReminderToManager,
      },
    })
  }

  const sendEvent = (event: EventsOfTask, comment?: string, onCompleted?: () => void) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event,
        ...(comment && { comment }),
      },
      onCompleted: ({ performEventToTask: result }) => {
        if (result.not_managed) {
          removeAll()
          setFlashMessage(t('common.error.be.noManagerAssigned'))
        }

        if (onCompleted) return onCompleted()

        return window.location.reload()
      },
    })
  }

  return {
    loading,
    sendReminderToFrontline,
    sendReminderToManager,
    sendEvent,
  }
}

export default usePerformEventToTask
