import React, { useCallback, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { NewCalendarM, PersonalDataFormM, SecureConnection, WorkTimeControlM } from 'icons'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import { refreshHighSecTriggers } from 'pages/UserSettings/constants'
import useTenantPageData from 'pages/UserSettings/hooks/useTenantPageData'
import { headerClasses } from 'pages/UserSettings/styles'
import { useHistory, useParams } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { taskTypes, TaskTypeT } from 'shared/constants'
import palette from 'utils/theme/palette'
import { classes } from './styles'

const TenantRemindersPageContent: React.FC = () => {
  const { tenantUuid } = useParams<{ tenantUuid: string }>()
  const history = useHistory()
  const { t } = useFavurTranslation()

  const { loading, tenant } = useTenantPageData(tenantUuid)

  const gotoBackLink = useCallback(() => {
    history.goBack()
  }, [history])

  const getReminderAmount = useCallback(
    (snakeType: TaskTypeT) => {
      if (!tenant?.reminders) return 0
      const reminderObject = tenant.reminders.find((reminder) => reminder.type === snakeType)
      if (!reminderObject) return 0
      return Number(reminderObject.first > 0) + Number(reminderObject.second > 0) + Number(reminderObject.third > 0)
    },
    [tenant],
  )

  const taskTypeCtas = useMemo(
    () => [
      {
        title: t('page.userSettings.tenantReminders.monthlySheetReminder'),
        reminderQuantity: getReminderAmount(taskTypes.monthlySheet),
        icon: <WorkTimeControlM fill={palette.primary.main} />,
        workflowType: taskTypes.monthlySheet,
      },
      {
        title: t('page.userSettings.tenantReminders.shareUserDataReminder'),
        reminderQuantity: getReminderAmount(taskTypes.shareUserData),
        icon: <PersonalDataFormM fill={palette.primary.main} />,
        workflowType: taskTypes.shareUserData,
      },
      {
        title: t('page.userSettings.tenantReminders.shiftUpdateReminder'),
        reminderQuantity: getReminderAmount(taskTypes.shiftUpdate),
        icon: <NewCalendarM fill={palette.primary.main} />,
        workflowType: taskTypes.shiftUpdate,
      },
      {
        title: t('page.userSettings.tenantReminders.secureConnection'),
        reminderQuantity: getReminderAmount(taskTypes.secureConnection),
        icon: <SecureConnection stroke={palette.primary.main} />,
        workflowType: taskTypes.secureConnection,
      },
    ],
    [getReminderAmount, t],
  )

  const getReminderQuantityText = useCallback(
    (quantity: number) => {
      if (quantity === 0) {
        return t('page.userSettings.tenantReminders.noActiveReminders')
      }
      return t('page.userSettings.tenantReminders.xActiveReminders', { reminderQuantity: quantity })
    },
    [t],
  )

  return (
    <Page
      header={<BackwardLink onClickIcon={gotoBackLink} title={t('page.userSettings.tenantReminders.header')} />}
      sxHeader={headerClasses.subHeader}
    >
      {loading || !tenant ? (
        <SimpleLoading />
      ) : (
        <Box sx={classes.sectionsContainer} {...refreshHighSecTriggers}>
          <Typography variant="body2" sx={classes.explanation}>
            {t('page.userSettings.tenantReminders.explanation')}
          </Typography>
          {taskTypeCtas.map((cta) => (
            <UserSettingsSectionCTA
              key={cta.title}
              title={cta.title}
              icon={cta.icon}
              subtitle={getReminderQuantityText(cta.reminderQuantity)}
              onClick={() => {
                history.push(routes.tenantRemindersList(tenantUuid, cta.workflowType))
              }}
            />
          ))}
        </Box>
      )}
    </Page>
  )
}

export default TenantRemindersPageContent
