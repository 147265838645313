import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { ROLE_VIEWS } from 'constants/roles'
import useHistoryUtils from 'hooks/useHistoryUtils'
import { ArrowRightM } from 'icons'
import routes from 'services/RoutesProvider/routes'
import { SubcontentBlockT } from 'types'
import palette from 'utils/theme/palette'
import { subcontentBlockStyles } from '../styles'

interface ISubContentBlockProps {
  data: SubcontentBlockT
  title?: string
}

const SubContentBlock: React.FC<ISubContentBlockProps> = ({ data }) => {
  const [isTitleTwoLines, setIsTitleTwoLines] = useState(false)
  const titleRef = useRef<HTMLHeadingElement>(null)
  const { pushWithRole } = useHistoryUtils()

  const checkTitleHeight = useCallback(() => {
    if (!titleRef.current) return
    // Calculate if the current title is more than one line
    const titleHeight = titleRef.current.offsetHeight
    const lineHeight = parseInt(window.getComputedStyle(titleRef.current).lineHeight)
    // Using 1.4 as a threshold for the height of the line to see if it expands more than one line
    setIsTitleTwoLines(titleHeight >= lineHeight * 1.4)
  }, [titleRef])

  useEffect(() => {
    if (!titleRef.current) return

    const observer = new ResizeObserver(checkTitleHeight)
    observer.observe(titleRef.current)
  }, [data.title, checkTitleHeight])

  return (
    <Box
      onClick={() => {
        pushWithRole(`${routes.cmsContentView}/${data.subcontentUuid}`, ROLE_VIEWS.frontliner)
      }}
      sx={subcontentBlockStyles.card}
    >
      <Box sx={subcontentBlockStyles.imageContainer}>
        {data.src ? <Box component="img" sx={subcontentBlockStyles.image} src={data.src}></Box> : null}
      </Box>

      <Box sx={subcontentBlockStyles.container}>
        <Box sx={subcontentBlockStyles.textContainer}>
          {data.title ? (
            <Typography sx={subcontentBlockStyles.text} variant="body1" ref={titleRef}>
              {data.title}
            </Typography>
          ) : null}
          {data.previewText ? (
            <Typography
              variant="body2"
              sx={[subcontentBlockStyles.text, isTitleTwoLines ? subcontentBlockStyles.oneLine : null]}
              color={palette.grey[600]}
            >
              {data.previewText}
            </Typography>
          ) : null}
        </Box>

        <Box sx={subcontentBlockStyles.iconContainer}>
          <ArrowRightM fill={palette.primary.main} />
        </Box>
      </Box>
    </Box>
  )
}

export default SubContentBlock
