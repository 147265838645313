import React, { useMemo, useState } from 'react'
import { Typography } from '@mui/material'
import { BoxedLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useBannerHighSecurityConnectionEnable from 'hooks/useBannerHighSecurityConnectionEnable'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useOwnersOfUser } from 'hooks/useOwnersOfUser'
import useRolesViews from 'hooks/useRolesViews'
import { AddM } from 'icons'
import FloatingBottomButton from '../../components/FloatingBottomButton'
import CreateTeamDialog from './components/CreateTeamDialog'
import TeamsHeader from './components/TeamsHeader'
import TeamsList from './components/TeamsList'
import { getFirstOwner, getOwnerPersonsFromOwnerId, parseUrlOwnerId } from './utils'

const TeamsPageContent: React.FC<{ ownerId: string }> = ({ ownerId: ownerIdParam }) => {
  const { loading: loadingBanner } = useBannerHighSecurityConnectionEnable()

  const { t } = useFavurTranslation()

  const [open, setOpen] = useState(false)

  const { loading, owners } = useOwnersOfUser({ teamManagersOnly: true })
  const { setActiveView, roles, activeView } = useRolesViews()
  if (activeView !== roles.office) setActiveView(roles.office)

  const ownerId = useMemo(() => parseUrlOwnerId(ownerIdParam, owners) ?? getFirstOwner(owners), [ownerIdParam, owners])
  const ownerPersonsSelected = useMemo(() => getOwnerPersonsFromOwnerId(ownerId, owners), [ownerId, owners])

  if (loadingBanner || loading || !owners || !ownerId) {
    return (
      <Page header={<Typography variant="h2">{t('page.team.header.title')}</Typography>}>
        <BoxedLoading />
      </Page>
    )
  }

  return (
    <Page header={<TeamsHeader owners={owners} ownerId={ownerId} />} hasFloatingButtons>
      <TeamsList ownerPersons={ownerPersonsSelected} />
      <FloatingBottomButton
        Icon={AddM}
        label={t('page.teams.buttons.newTeam')}
        onClick={() => setOpen(true)}
        testId="create-team-btn"
        nativeBottomMargin
      />
      <CreateTeamDialog open={open} setOpen={setOpen} ownerPersons={ownerPersonsSelected} />
    </Page>
  )
}

export default TeamsPageContent
