import React from 'react'
import Tutorial from 'components/Tutorial'
import IntroPage from 'components/Tutorial/IntroPage'
import StepPage from 'components/Tutorial/StepPage'
import { TutorialStepT } from 'components/Tutorial/types'
import routes from 'services/RoutesProvider/routes'
import { TutorialProps } from '../types'

const AbsenceplanFrontliner: React.FC<TutorialProps> = ({
  tutorialName,
  t,
  step,
  setStep,
  history,
  goBack,
}: TutorialProps) => {
  const goToAbsenceplanner = () => {
    history.replace(routes.absencePlanner)
  }

  const stepPages: TutorialStepT[] = [
    {
      key: 'step1',
      titleText: t(`tutorial.absenceplan.Frontliner.step1.title`),
      mainText: t('tutorial.absenceplan.Frontliner.step1.text'),
    },
    {
      key: 'step2',
      titleText: t(`tutorial.absenceplan.frontliner.step2.title`),
      mainText: t(`tutorial.absenceplan.frontliner.step2.text`),
    },
    {
      key: 'step3',
      titleText: t(`tutorial.absenceplan.Frontliner.step3.title`),
      mainText: t(`tutorial.absenceplan.Frontliner.step3.text`),
    },
    {
      key: 'step4',
      close: () => goToAbsenceplanner(),
      titleText: t(`tutorial.absenceplan.Frontliner.step4.title`),
      mainText: t(`tutorial.absenceplan.Frontliner.step4.text`),
    },
  ]

  const stepPagesComponents = stepPages.map((stepPage) => (
    <StepPage key={stepPage.key} tutorialName={tutorialName} stepProps={stepPage} />
  ))

  const introPage = <IntroPage tutorialName={tutorialName} stepName="cover" close={() => goBack(routes.tasks)} />

  return (
    <Tutorial
      introPage={introPage}
      stepPages={stepPagesComponents}
      step={step}
      setStep={setStep}
      onSwipeEnd={() => goToAbsenceplanner()}
    />
  )
}

export default AbsenceplanFrontliner
