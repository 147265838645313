import { teamMemberProperties } from 'pages/Teams/queries'
import { onlyShiftFields } from 'shared/queries'
import { gql } from '@apollo/client'
import { TaskStateT } from './types'

export const tasksQueryKey = (state: TaskStateT, office: boolean) => {
  return `tasks${office ? 'Office' : ''}${state.charAt(0).toUpperCase() + state.slice(1)}`
}

const monthlySheetDataFields = `
... on MonthlySheetData {
  documentId,
  documentUuid
  endDate,
  startDate,
  documentCreatedAt,
  documentAcceptedAt,
}`

const taskListAbsenceFields = `
... on TaskListAbsenceData {
  dateFrom
  dateTo
  dates {
    date
    type
    period
  }
  reviewerComment
}`

const taskListShareUserDataFields = `
... on ShareUserData {
  reviewerComment
  userComment
  sentDataOn
}`

const taskListShiftUpdateFields = `
... on ShiftUpdateData {
  acknowledgedAt
  publishedAt
}`

const absenceDataFields = `
... on AbsenceData {
  dateFrom
  dateTo
  reviewerComment
  dates {
    date
    type
    period
  }
}`

const documentMontlhySheetProperties = `
  id
  documentType
  documentUuid
  favurUuid
  status
  data
  lang
  createdByMirus
    `

const timelineFields = `
  id
  document {
    ${documentMontlhySheetProperties}
  }
  badge
  comment
  authorId
  authorFirstName
  authorLastName
  authorAvatarUrl
  authorIsSystem
  insertedAt
  isDataForm
  tenantName
`

const taskFields = `
    id
    comment
    insertedAt
    updatedAt
    userName
    triggeredByUserName
    taskType
    tenantName
    favurUuid
    status
    statusFe
    timeline {
      ${timelineFields}
    }
    shiftsUpdatedHistory {
      ${onlyShiftFields}
    }
    shiftsUpdated {
      ${onlyShiftFields}
    }
    taskData {
      __typename
      ${taskListAbsenceFields}
      ${monthlySheetDataFields}
      ${taskListShareUserDataFields}
      ${taskListShiftUpdateFields}
    }
`

const taskPaginatedFields = `
    ${taskFields}
    statusFe
    person {
      id
      firstName
      lastName
      user {
        firstName
        lastName
      }
    }
    personTriggeredBy {
      firstName
      lastName
      user {
        firstName
        lastName
      }
    }
`

const taskQueryProperties = `
    comment
    insertedAt
    updatedAt
    userName
    triggeredByUserName
    taskType
    tenantName
    favurUuid
    status
    statusFe
    person {
      id
      firstName
      lastName
      tenant {
        name
      }
      user {
        firstName
        lastName
        avatarUrl
      }
    }
    personTriggeredBy {
      firstName
      lastName
      user {
        firstName
        lastName
        avatarUrl
      }
    }
    shiftsUpdatedHistory {
      ${onlyShiftFields}
    }
    shiftsUpdated {
      ${onlyShiftFields}
    }
    timeline {
      ${timelineFields}
    }
    taskData {
      ${absenceDataFields}
      ${monthlySheetDataFields}
      ${taskListShareUserDataFields}
      ${taskListShiftUpdateFields}
    }`

const taskQueryMonthlySheetProperties = `
    comment
    insertedAt
    updatedAt
    userName
    triggeredByUserName
    taskType
    tenantName
    favurUuid
    status
    statusFe
    person {
      id
      firstName
      lastName
      user {
        firstName
        lastName
        avatarUrl
      }
      tenant{
        name
      }
    }
    taskMonthlySheet {
      document {
        ${documentMontlhySheetProperties}
      }
    }
    taskData {
      ${monthlySheetDataFields}
    }
    timeline {
      ${timelineFields}
    }
    `

export const taskQueryMonlthySheet = gql`
    query Task($favurUuid: String!, $office: Boolean) {
      task(favurUuid: $favurUuid, office: $office) {
        ${taskQueryMonthlySheetProperties}
      }
    }`

const taskQueryAbsencesProperties = `
    comment
    insertedAt
    updatedAt
    userName
    triggeredByUserName
    taskType
    tenantName
    favurUuid
    status
    statusFe
    person {
      id
      firstName
      lastName
      favurUuid
      owner {
        id
      }
      user {
        firstName
        lastName
        avatarUrl
      }
      teamPermissions {
        teamId
      }
      tenant {
        id
        name
      }
    }
    personTriggeredBy {
      firstName
      lastName
      user {
        firstName
        lastName
        avatarUrl
      }
    }
    taskData {
      ${absenceDataFields}
    }
    timeline {
      ${timelineFields}
    }
    `

export const taskQueryAbsences = gql`
    query Task($favurUuid: String!, $office: Boolean) {
      task(favurUuid: $favurUuid, office: $office) {
        ${taskQueryAbsencesProperties}
      }
    }`

export const tasksPaginatedQuery = gql`
    query tasksPaginated($filters: TaskFilters, $limit: Int, $offset: Int, $office: Boolean!){
      tasksPaginated(filters: $filters, limit:$limit, offset:$offset, office: $office){
        totalCount
        list{
          ${taskPaginatedFields}
        }
      }
    }`

export const tasksPaginatedQueryMinData = gql`
  query tasksPaginated($filters: TaskFilters, $limit: Int, $offset: Int, $office: Boolean!){
    tasksPaginated(filters: $filters, limit:$limit, offset:$offset, office: $office){
      totalCount
      list{
        id
        taskType
        favurUuid
        taskData {
          __typename
          ${taskListAbsenceFields}
          ${monthlySheetDataFields}
          ${taskListShareUserDataFields}
          ${taskListShiftUpdateFields}
        }
        person {
          id
          firstName
          lastName
        }
      }
    }
  }`

export const taskQueryByUuidKey = (favurUuid: string) => {
  return `taskByUuid-${favurUuid}`
}

export const taskQueryByUuid = (favurUuid: string, office = false) => {
  return gql`
    query {
      task(favurUuid: "${favurUuid}", office: ${office}) {
        ${taskQueryProperties}
      }
    }`
}

export const taskQueryApollo = gql`
  query Task($favurUuid: String!, $office: Boolean) {
    task(favurUuid: $favurUuid, office: $office) {
      ${taskQueryProperties}
    }
  }`

export const allManagedPersonsQuery = gql`
  query getAllManagedPersons($teamPermissions: [String]) {
    findAllManagedPersons(teamPermissions: $teamPermissions) {
      ${teamMemberProperties}
    }
  }
`

export const deleteTaskQuery = gql`
  mutation deleteTasks($favurUuid: String!, $personId: ID!) {
    deleteTask(favurUuid: $favurUuid, personId: $personId) {
      success
    }
  }
`

export const personsWithShareUserData = gql`
  query personsWithShareUserData {
    findAllPersons {
      ${teamMemberProperties}
    }
  }
`

export const absenceRequestsQuery = gql`
  query getAbsenceRequests($personIds: [ID]!, $date: String, $dateFrom: String, $dateTo: String, $statuses: [String]) {
    getAbsenceRequests(personIds: $personIds, date: $date, dateFrom: $dateFrom, dateTo: $dateTo statuses: $statuses) {
      id
      insertedAt
      favurUuid
      status
      person {
        id
      }
      taskData {
        ${absenceDataFields}
      }
    }
  }
`

export const performEventToTask = gql`
  mutation performEventToTask($favurUuid: String!, $event: String!, $comment: String) {
    performEventToTask(favurUuid: $favurUuid, event: $event, comment: $comment) {
      success
      not_managed
    }
  }
`
