import { balanceQueryParams, estimationsQueryParams } from 'shared/queries'
import { gql } from '@apollo/client'

export const pastBalanceQuery = gql`
  query pastBalance($endDate: String!){
    balances(endDate: $endDate){
      ${balanceQueryParams}
    }
  }
`

export const balanceWithEstimationsQuery = gql`
  query balanceWithEstimations($estimateFromDay: utctimestamp!){
    improvedBalances(estimateFromDay: $estimateFromDay){
      ${balanceQueryParams}
      ${estimationsQueryParams}
    }
  }
`
