const semibold = 500
const baseTypography = {
  fontFamily: 'FiraSans, sans-serif',
  h1: {
    fontWeight: semibold,
    fontSize: '3rem',
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
  },
  h2: {
    fontWeight: semibold,
    fontSize: '2.5rem',
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
  },
  h3: {
    fontWeight: semibold,
    fontSize: '2.5rem',
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
  },
  h4: {
    fontWeight: semibold,
    fontSize: '1.75rem',
    lineHeight: 1.2,
    letterSpacing: '-0.007em',
  },
  h5: {
    fontWeight: semibold,
    fontSize: '1.5rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
  h6: {
    fontWeight: semibold,
    fontSize: '1.25rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
  subtitle1: {
    fontWeight: semibold,
    fontSize: '1rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
  subtitle2: {
    fontWeight: semibold,
    fontSize: '0.875rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
  body1: {
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: 1.6,
    letterSpacing: '0.01em',
  },
  body2: {
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: 1.6,
    letterSpacing: '0.01em',
  },
  caption: {
    fontWeight: 'normal',
    fontSize: '0.75rem',
    lineHeight: 1.6,
    letterSpacing: '0.05em',
  },
  overline: {
    fontSize: '0.75rem',
    lineHeight: 1.6,
    letterSpacing: '0.08em',
  },
}

const typography = {
  ...baseTypography,
  body1Scratched: {
    ...baseTypography.body1,
    textDecoration: 'line-through',
  },
  body2Scratched: {
    ...baseTypography.body2,
    textDecoration: 'line-through',
  },
  buttonMedium: {
    fontWeight: semibold,
    fontSize: '1rem',
    lineHeight: 1.72,
    letterSpacing: '0.03em',
  },
  buttonSmall: {
    fontWeight: semibold,
    fontSize: '0.875rem',
    lineHeight: 1.72,
    letterSpacing: '0.03em',
  },
  chipBig: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
  chipSmall: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
}

export default typography
