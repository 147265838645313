import { OwnerPersons } from 'hooks/useOwnersOfUser'
import { WorkflowsT, PersonT, PersonTeamPermissionT, RolesT } from 'types'
import { dateToISOStringWithoutTime, newDateWithoutTime } from 'utils/date'
import type { MemberChangesT, MembersMapT } from './types'

export const checkNameInput = (input: string, { isMultiLang = false }: { isMultiLang?: boolean } = {}): boolean => {
  if (!isMultiLang) {
    return true
  }
  return input !== null && input.length > 0
}

export const normalizeTeamMembers = ({
  currentMembers,
  originalMembers,
}: {
  currentMembers: MembersMapT
  originalMembers: MembersMapT
}): MemberChangesT => {
  const initialMemberChanges: MemberChangesT = {
    add: [],
    delete: [],
  }

  return Array.from(currentMembers, ([personId, isInTeam]) => ({
    personId,
    isInTeam,
  })).reduce((prev, curr) => {
    const originalValue = originalMembers.get(curr.personId)

    if (originalValue !== undefined && originalValue !== curr.isInTeam) {
      if (curr.isInTeam) {
        return { ...prev, add: [...prev.add, curr.personId] }
      }

      return { ...prev, delete: [...prev.delete, curr.personId] }
    }

    return prev
  }, initialMemberChanges)
}

export const unselectMembers = (members: Map<number, boolean>) => {
  return Array.from(members.keys()).reduce((acc, key) => {
    return acc.set(key, false)
  }, new Map<number, boolean>(members))
}

export const getPersonsByPermissions = (personTeamPermissions: PersonTeamPermissionT[]) => {
  const personTeamPermsFields = [...Object.values(WorkflowsT), ...Object.values(RolesT)]
  const initialWorflows = personTeamPermsFields.reduce((acc, workflowName) => {
    acc.set(workflowName, [])
    return acc
  }, new Map<string, PersonT[]>())

  const personsPerPermission = personTeamPermissions.reduce((acc, tp) => {
    personTeamPermsFields.forEach((workflowName) => {
      if (tp[workflowName] && tp.person) {
        const persons = acc.get(workflowName) as PersonT[]
        acc.set(workflowName, [...persons, tp.person])
      }
    })

    return acc
  }, initialWorflows)

  return personsPerPermission
}

export const isPermissionMissing = (personsByPermission: Map<string, PersonT[]>) => {
  const absencesPermissions = personsByPermission.get(WorkflowsT.absenceManager)
  if (!absencesPermissions || !absencesPermissions.length) {
    return true
  }

  const monthlySheetPermissions = personsByPermission.get(WorkflowsT.monthlySheetManager)
  if (!monthlySheetPermissions || !monthlySheetPermissions.length) {
    return true
  }

  const shareUserDataPermissions = personsByPermission.get(WorkflowsT.shareUserDataManager)
  if (!shareUserDataPermissions || !shareUserDataPermissions.length) {
    return true
  }

  const shiftUpdatePermissions = personsByPermission.get(WorkflowsT.shiftUpdateManager)
  if (!shiftUpdatePermissions || !shiftUpdatePermissions.length) {
    return true
  }

  const secureConnectionPermissions = personsByPermission.get(WorkflowsT.secureConnectionManager)
  if (!secureConnectionPermissions || !secureConnectionPermissions.length) {
    return true
  }

  return false
}

export const parseUrlOwnerId = (ownerIdParam: string, owners: OwnerPersons[]): number | undefined => {
  if (!ownerIdParam || !owners) {
    return undefined
  }

  const ownerIdParamInt = parseInt(ownerIdParam)
  if (owners.filter((owner) => Number(owner.owner.id) === ownerIdParamInt).length > 0) {
    return ownerIdParamInt
  }

  return undefined
}

export const getFirstOwner = (owners: OwnerPersons[]) => {
  if (owners.length === 0) {
    return undefined
  }
  return owners[0].owner.id
}

export const getOwnerPersonsFromOwnerId = (ownerId: number | undefined, owners: OwnerPersons[]) => {
  return owners.find((op) => Number(op.owner.id) === Number(ownerId))
}

const hasAnyPermission = (personTeamPermission: PersonTeamPermissionT) =>
  personTeamPermission.absenceManager ||
  personTeamPermission.monthlySheetManager ||
  personTeamPermission.shareUserDataManager ||
  personTeamPermission.shiftUpdateManager

export const hasResignedManagers = (personTeamPermissions: PersonTeamPermissionT[]): boolean => {
  const today = dateToISOStringWithoutTime(newDateWithoutTime())
  const resignedManagers = personTeamPermissions.reduce((acc, ptp) => {
    if (!hasAnyPermission(ptp)) return acc
    const withdrawalDate = ptp.person?.workWithdrawal
    if (!withdrawalDate || withdrawalDate >= today) return acc
    return [...acc, ptp]
  }, [] as PersonTeamPermissionT[])
  return resignedManagers.length > 0
}
