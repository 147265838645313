import React from 'react'
import PinLogin from 'pages/PinLogin'
import { RouteComponentProps } from 'react-router-dom'
import TeamsPageContent from './TeamsPageContent'

const TeamsPage: React.FC<RouteComponentProps<{ ownerId: string }>> = ({ match }) => {
  const { ownerId } = match.params
  return (
    <PinLogin>
      <TeamsPageContent ownerId={ownerId} />
    </PinLogin>
  )
}

export default TeamsPage
