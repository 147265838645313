import React from 'react'
import { Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'

interface IDialogWindowProps {
  bodyText?: string
  cancelText?: string
  children?: React.ReactNode
  confirmDisabled?: boolean
  confirmText?: string
  onCancel?: () => void
  onConfirm?: () => void
  open: boolean
  titleText?: string
}

const DialogWindow: React.FC<IDialogWindowProps> = ({
  bodyText,
  cancelText,
  children,
  confirmDisabled,
  confirmText,
  onCancel,
  onConfirm,
  open,
  titleText,
}) => {
  const { t } = useFavurTranslation()
  return (
    <Dialog
      actions={
        <>
          {onCancel && (
            <Button onClick={onCancel} color="secondary" size="large" data-testid="cancel-dialog-btn">
              {cancelText ?? t('common.cancel')}
            </Button>
          )}

          {onConfirm && (
            <Button
              onClick={onConfirm}
              color="secondary"
              size="large"
              disabled={confirmDisabled ?? false}
              data-testid="confirm-dialog-btn"
            >
              {confirmText ?? t('common.confirm')}
            </Button>
          )}
        </>
      }
      title={titleText}
      open={open}
    >
      {bodyText && <Typography variant="body2">{bodyText}</Typography>}
      {children && children}
    </Dialog>
  )
}

export default DialogWindow
