import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { IActionButton } from 'pages/SecuritySetup/types'
import { classes } from './styles'

const Reset: React.FC<IActionButton> = ({ onClick, disabled }) => {
  const { t } = useFavurTranslation()

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      data-testid="set-pin-reset"
      sx={[classes.button, classes.resetButton]}
    >
      {t('secureConnection.setPin.buttons.reset')}
    </Button>
  )
}

export default Reset
