import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const classes = {
  pinDisplayContainer: {
    paddingTop: '140px',
  },
  changePinLengthButton: {
    marginTop: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.primary[900],
  },
  errorAlert: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
    gap: '4px',
  },
  errorLabel: { color: palette.error.main },
  numpadContainer: {
    marginBottom: '45px',
  },
  numpadContainerRepeat: {
    marginBottom: '45px',
    marginTop: '40px',
  },
  pinRepeatButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}
