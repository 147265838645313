import { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { OwnerPersons } from 'hooks/useOwnersOfUser'
import { useAppStatesContext } from 'services/AppStates'
import theme from 'utils/theme'
import { SELEC_OWNER_FILTER_NAME } from './useFilter'

const useAbsencePlannerState = () => {
  const { get, set } = useAppStatesContext()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedOwnerId, setSelectedOwnerId] = useState<number | undefined>(get(SELEC_OWNER_FILTER_NAME))
  const [owners, setOwners] = useState<OwnerPersons[] | undefined>()

  const storeAndSetSelOwnerId = useCallback(
    (value: number | undefined) => {
      set(SELEC_OWNER_FILTER_NAME, value)
      localStorage.setItem(SELEC_OWNER_FILTER_NAME, `${value}`)
      setSelectedOwnerId(value)
    },
    [set, setSelectedOwnerId],
  )

  useEffect(() => {
    const localStorageOwnerId = localStorage.getItem(SELEC_OWNER_FILTER_NAME)
    if (localStorageOwnerId) {
      const ownerIdNumber = parseInt(localStorageOwnerId)
      set(SELEC_OWNER_FILTER_NAME, ownerIdNumber)
      setSelectedOwnerId(ownerIdNumber)
    }
    // this rule has to be removed because if the dependencies are on, it triggers infinite re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isSmallScreen,
    owners,
    setOwners,
    selectedOwnerId,
    setSelectedOwnerId: storeAndSetSelOwnerId,
  }
}

export default useAbsencePlannerState
