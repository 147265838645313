import React from 'react'
import DashboardPage from 'pages/Dashboard'
import POCPage from 'redesign/components/templates/POCPage'

const POCDashboardPage: React.FC = () => {
  return (
    <POCPage>
      <DashboardPage />
    </POCPage>
  )
}

export default POCDashboardPage
