import React, { useCallback, useEffect, useState } from 'react'
import { NativeBiometric } from 'capacitor-native-biometric'
import CenteredBox from 'components/CenteredBox'
import { defaultSuccessPageTimeout } from 'components/Success'
import { PIN_KEYCHAIN_SERVER, PIN_LENGTHS, SECURITY_PROCESS_TYPES } from 'constants/highSecurityConnection'
import { ROLE_VIEWS } from 'constants/roles'
import useDialogs from 'hooks/useDialogs'
import useHistoryUtils from 'hooks/useHistoryUtils'
import usePinContext from 'hooks/usePinContext'
import { useSessionTimeout } from 'hooks/useSessionTimeout'
import usePinKeysStorage from 'pages/PinLogin/usePinKeysStorage'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { ExtendedStepProps, SetPinStepProps } from 'types'
import { classes } from '../styles'
import PinEnter from './PinEnter'
import PinRepeat from './PinRepeat'

interface ISetPinProps {
  additionalProperties?: ExtendedStepProps
}

const SetPin: React.FC<ISetPinProps> = ({ additionalProperties }) => {
  const props = additionalProperties as SetPinStepProps
  const { pin, firstPin, secondPin, setFirstPin, setSecondPin, taskUuid } = props

  const securityProcessType = SECURITY_PROCESS_TYPES.securitySetup
  const [pinLength, setPinLength] = useState(PIN_LENGTHS.default)
  const { pushWithRole } = useHistoryUtils()
  const { getBioPinInUse, storePinInKeychain } = usePinKeysStorage()

  const onConfirmPin = props.onConfirmPin
  const [isPinEntered, setIsPinEntered] = useState(false) //Show Pin enter or Pin repeat step
  const [showSuccess, setShowSuccess] = useState(false)
  const [showErrorMatching, setShowErrorMatching] = useState(false)
  const [hasBioPin, setHasBioPin] = useState(false)

  const history = useHistory()
  const { openDialog } = useDialogs()
  const { securitySetupTokenConfirmed, pinChangePinConfirmed, setPinContext } = usePinContext()

  useEffect(() => {
    const getHasBioPin = async () => {
      const value = await getBioPinInUse()
      setHasBioPin(value)
    }
    getHasBioPin()
  }, [getBioPinInUse])

  // security timeout handler
  const timeoutAction = useCallback(() => {
    openDialog(securityProcessType)
    if (taskUuid) {
      pushWithRole(routes.secureConnectionWithTask(taskUuid), ROLE_VIEWS.frontliner)
    }
    history.push(routes.dashboard)
  }, [history, openDialog, pushWithRole, securityProcessType, taskUuid])

  useSessionTimeout(securityProcessType, timeoutAction)

  // Pin enter
  const onContinue = useCallback(() => {
    if (firstPin?.length === pinLength) {
      setPinContext({ pin: firstPin, pinLength, securitySetupTokenConfirmed, pinChangePinConfirmed })
      setIsPinEntered(true)
    }
    return null
  }, [firstPin, pinLength, setPinContext, securitySetupTokenConfirmed, pinChangePinConfirmed])

  useEffect(() => {
    if (!showSuccess) return undefined
    const successTimeout = setTimeout(() => {
      history.push(routes.dashboard)
      setPinContext({})
    }, defaultSuccessPageTimeout)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [showSuccess, history, setPinContext])

  useEffect(() => {
    if (!securitySetupTokenConfirmed) {
      timeoutAction()
      return
    }
  }, [securitySetupTokenConfirmed, pinChangePinConfirmed, securityProcessType, timeoutAction])

  useEffect(() => {
    if (showSuccess) {
      history.push(taskUuid ? `${routes.secureConnectionSuccess}/${taskUuid}` : routes.secureConnectionSuccess)
    }
  }, [showSuccess, history, taskUuid])

  // Pin repeat
  const handleOnConfirm = useCallback(async () => {
    if (secondPin !== pin) {
      setShowErrorMatching(true)
      setSecondPin('')
      return
    }

    const response = await onConfirmPin()
    await storePinInKeychain(pin)

    if (response?.setPinAndEstablishSecureConnection?.success === true) {
      if (hasBioPin && pin) {
        await NativeBiometric.setCredentials({
          username: 'pin',
          password: pin,
          server: PIN_KEYCHAIN_SERVER,
        })
      }
      setShowErrorMatching(false)
      setShowSuccess(true)
    }
  }, [hasBioPin, onConfirmPin, pin, secondPin, setSecondPin, storePinInKeychain])

  const handleOnReset = useCallback(() => {
    setPinContext({ pin: '', pinLength: PIN_LENGTHS.default, pinChangePinConfirmed, securitySetupTokenConfirmed })
    setIsPinEntered(false)
    setShowErrorMatching(false)
  }, [pinChangePinConfirmed, securitySetupTokenConfirmed, setPinContext])

  const handleSetSecondPin = useCallback(
    (newValue: string) => {
      setSecondPin(newValue)
      setShowErrorMatching(false)
    },
    [setSecondPin],
  )

  return (
    <CenteredBox sx={classes.root}>
      {isPinEntered ? (
        <PinRepeat
          onConfirm={handleOnConfirm}
          onReset={handleOnReset}
          pin={secondPin}
          pinLength={pinLength}
          setPin={handleSetSecondPin}
          showErrorMatching={showErrorMatching}
        />
      ) : (
        <PinEnter
          onContinue={onContinue}
          pin={firstPin}
          pinLength={pinLength}
          setPin={setFirstPin}
          setPinLength={setPinLength}
        />
      )}
    </CenteredBox>
  )
}

export default SetPin
