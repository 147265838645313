import React from 'react'
import Tutorial from 'components/Tutorial'
import StepPage from 'components/Tutorial/StepPage'
import { TutorialStepT } from 'components/Tutorial/types'
import routes from 'services/RoutesProvider/routes'
import { Conversation, IdKey, Lock } from '../../../icons'
import { ICON_SIZE_TUTORIAL } from '../../../icons/constants'
import theme from '../../../utils/theme'
import { SecureConnectionTutorialProps } from './types'

const SecureConnection: React.FC<SecureConnectionTutorialProps> = ({
  tutorialName,
  t,
  step,
  setStep,
  history,
  taskId,
}: SecureConnectionTutorialProps) => {
  const titleTextTypographyVariant = 'h5'
  const mainTextTypographyVariant = 'body1'
  const iconSize = ICON_SIZE_TUTORIAL
  const iconFillColor = theme.palette.primary.main

  const goToStepper = () => history.replace(routes.securitySetup)

  const goToTask = () => history.replace(routes.secureConnectionWithTask(taskId as string))

  const close = () => (taskId ? goToTask() : goToStepper())

  const stepPages: TutorialStepT[] = [
    {
      key: 'step1',
      titleText: t(`tutorial.secureconnection.step1.titleText`),
      mainText: t(`tutorial.secureconnection.step1.text`),
      titleTextTypographyVariant,
      mainTextTypographyVariant,
      icon: <Conversation fill={iconFillColor} size={iconSize} />,
    },
    {
      key: 'step2',
      titleText: t(`tutorial.secureconnection.step2.titleText`),
      mainText: t(`tutorial.secureconnection.step2.text`),
      titleTextTypographyVariant,
      mainTextTypographyVariant,
      icon: <IdKey fill={iconFillColor} size={iconSize} />,
    },
    {
      key: 'step3',
      titleText: t(`tutorial.secureconnection.step3.titleText`),
      mainText: t(`tutorial.secureconnection.step3.text`),
      titleTextTypographyVariant,
      mainTextTypographyVariant,
      icon: <Lock fill={iconFillColor} size={iconSize} />,
      close,
      mainAction: goToStepper,
      mainActionLabel: t(`tutorial.secureconnection.step3.goToStepper`),
      secondaryAction: taskId ? goToTask : undefined,
      secondaryActionLabel: t(`tutorial.secureconnection.step3.goToDetail`),
    },
  ]

  const stepPagesComponents = stepPages.map((stepPage) => (
    <StepPage key={stepPage.key} tutorialName={tutorialName} stepProps={stepPage} />
  ))

  return <Tutorial stepPages={stepPagesComponents} step={step} setStep={setStep} onSwipeEnd={close} />
}

export default SecureConnection
