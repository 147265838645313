import { useMemo } from 'react'
import { isThisMonth } from 'date-fns'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { BalanceObject } from 'types/balances'
import { newDateWithoutTime } from 'utils/date'
import { balanceWithEstimationsQuery, pastBalanceQuery } from './queries'

const useTimeBalances = (endDate: string) => {
  const isCurrent = useMemo(() => isThisMonth(newDateWithoutTime(endDate)), [endDate])
  const estimateFromDay = useMemo(() => newDateWithoutTime().toISOString(), [])

  const { data: improvedBalancesData, loading: improvedBalancesLoading } = useJamesApolloQuery<{
    improvedBalances?: BalanceObject[]
  }>(balanceWithEstimationsQuery, {
    variables: { estimateFromDay },
    skip: !isCurrent,
  })

  const { data: balancesData, loading: balancesLoading } = useJamesApolloQuery<{ balances?: BalanceObject[] }>(
    pastBalanceQuery,
    { variables: { endDate: endDate }, skip: isCurrent },
  )

  return {
    isCurrent,
    balances: improvedBalancesData?.improvedBalances ?? balancesData?.balances,
    loading: improvedBalancesLoading || balancesLoading,
  }
}

export default useTimeBalances
